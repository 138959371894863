
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.PasswordModalContent {
}

.PasswordModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.PasswordModalContent__content {
  max-width: 16em;
  padding-bottom: 16px;
  margin: auto;
}

.PasswordModalContent__content__sentence {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.PasswordModalContent__content__subSentence {
  text-align: center;
  font-size: 12px;
  color: $grey;
}

.PasswordModalContent__content__actions {
  text-align: center;
  font-size: 14px;
  color: $grey;
}

.PasswordModalContent__content__actions__passwordLost,
.PasswordModalContent__content__actions__signUp {
  &:hover {
    text-decoration: underline;
  }
}

.PasswordModalContent__content__loginForm__ignore {
  margin: auto;
  display: block;
}