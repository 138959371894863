
@import '../../../Color';
@import '../../../GlobalVariables';

.ApiDownPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../Pictures/illustrations/jungle_pattern.jpg');
  background-repeat: repeat;
  background-size: 250px;
}

.ApiDownPage__logo {
  background-image: url('../../../Pictures/Logos/logo.png');
  background-repeat: no-repeat;
  background-size: 30px auto;

  position: absolute;
  top: 15px;
  left: 20px;
  width: 100px;
  height: 100px;
}

.ApiDownPage__text {
  font-weight: 600;
}

.ApiDownPage__image {
  $size: 150px;
  height: $size;
  width: $size;
  border-radius: 50%;
  border: 5px solid $blue;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: none;
  }
}
