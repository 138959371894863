@import "../../../../../Color.scss";

.ExternalWebSiteContent {

}

.ExternalWebSiteContent__contentRow {
  padding: 32px;
}

.ExternalWebSiteContent__contentRow__title {
  text-align: center;
}

.ExternalWebSiteContent__contentRow__imageRow {
  margin-top: 1em;
  padding: 0px 32px;
  text-align: center;
}

.ExternalWebSiteContent__contentRow__imageRow__image {
  width: 100%;
  object-fit: contain;
  max-height: 150px; 
}

.ExternalWebSiteContent__contentRow__buttons {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    display: block;
    width: 100%;
    max-width: 250px;
    border-radius: 50px;
  }
  .validation {
    height: 50px;
  }
  .cancel {
    margin-top: 1em;
    color: $grey;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}
