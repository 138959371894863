@import '../../../../Color';
@import '../../../../GlobalVariables';

.InvoicesList {
}


.InvoicesList__error {
  color: $pink;
}

.InvoicesList__empty {
  text-align: center;
  color: $grey;
}