
@import '../../../Color';
@import '../../../GlobalVariables';

#SuccessPage {
    padding: 0px 20px;
    .successContainer {
        text-align: center;
    }
    .successRocket {
        max-width: 15em;
        width: 100%;
        margin: auto;
        display: block;
    }
    p {
        line-height: 28px;
        font-size: 17px;
    }
    button {
        width: 100%;
        height: 60px;
        font-size: 18px;
        font-weight: 500;
        background-color: $green;
    }
}


