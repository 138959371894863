
@import '../../../Color';
@import '../../../GlobalVariables';

.Dashboard {
    background: #FBFCFE;
    padding: 0px 32px;
    .container {
        max-width: 65em;
        margin: auto;
        overflow: hidden;
        width: 100%;
        h2 {
            margin-top: 62px;
            margin-bottom: 8px;
            font-size: 30px !important;
            font-family: 'Muli', sans-serif;
            font-weight: 700;
        }
        p {
            max-width: 800px;
            margin: auto;
            text-align: center;
            line-height: 25px;
        }
        .Dashboard__image {
            margin-top: 2em;
            img {
                display: block;
                width: 100%;
                max-width: 50em;
                margin: auto;
            }
        }
    }
}

.Dashboard__subtitle {
    font-size: 18px;
}


