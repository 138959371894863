
@import '../../../Color';
@import '../../../GlobalVariables';

#UserInformations {
    padding: 0px 20px;
}

.formBody__noPlan {
    min-height: 100px;
    text-align: center;
    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 16px;
    }
    .formBody__button {
        margin-top: 16px;   
    }
}

.formBody__loadingPlan {
    text-align: center;
    img {
        width: 60px;
        height: auto;
        margin-bottom: 16px;
    }
}


