
@import '../../../Color';
@import '../../../GlobalVariables';

#NavBar {
  //background-color: white;
  //border-bottom: 1px solid  $grey-light;
  position: absolute;
  top:0px;
  left:0px;
  right:0px;
  z-index: 3;
  height:70px;
  transition-property: top;
  transition-duration: 400ms;
  max-width: 1600px;
  margin: auto;
  &.close {
    top:-120px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 21px;
}

.NavBar_logoContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.NavBar_logoContainer__logoWrapper {
  display: block;
  padding: 0 12px;
  transition: padding 500ms;
  @media screen and (max-width: 900px) {
    padding: 0 12px 0 0;
  }
  box-sizing: border-box;
}

.NavBar_logoContainer__logoWrapper__logo {
  display: block;
  width: 24px;
  height: 40px;
  background-image: url('../../../Pictures/Logos/logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &.home {
    @media screen and (max-width: 900px) {
      background-image: url('../../../Pictures/Logos/logo_white.svg');
    }
  }
}

.NavBar_logoContainer__mixlabWrapper {
  font-weight: 800;
  transition: color 500ms;
  color: $font-color;
  &:hover {
    text-decoration: none;
  }
  @media screen and (max-width: 900px) {
    color: white;
  }
}

.NavBar__pricingLink {
  color: $font-color;
  font-weight: 600;
  &:hover {
    color: $blue;
    text-decoration: none;
  }
  @media screen and (max-width: 900px){
    display: none;
  }
}

.NavBar__buttons__buttonLink {
  &.newMixlab {
    @media screen and (max-width: 900px){
      display: none;
    }
  }
}

.NavBar__buttons__buttonLink__button {
  padding: 10px 25px 11px 25px;
}

.NavBar__buttons__buttonLink__button__trial {
  width: 160px;
}

.NavBar__buttons {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit);
  grid-column-gap: 12px;
}


.NavBar__buttons__buttonLink__button__login {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition: background-color 500ms, color 500ms, border 500ms;
  @media screen and (max-width: 1250px) and (min-width:900px){
    background-color: $blue;
    color: white; 
    border-color: $blue;
  }
}


