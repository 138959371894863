
@import '../../../Color';
@import '../../../GlobalVariables';

.FooterPage {}

.FooterPage__grid {
  display: flex;
  padding: 32px;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 600px){
    flex-direction: column;
    text-align: center;
  }
}

.FooterPage__column__side {
  flex-shrink: 0;
  padding: 12px;
}

.FooterPage__columns {
  flex-shrink: 1;
  display: grid;
  grid-template-columns: repeat(4, 241px);
  @media screen and (max-width: 1100px){
    grid-template-columns: repeat(3, 241px);
  }
  @media screen and (max-width: 900px){
    grid-template-columns: 241px 180px;
  }
  @media screen and (max-width: 600px){
    display: block;
  }
}

.FooterPage__column {
  padding: 12px;
}

.FooterPage__column__logo {
  width: 30px;
}

.FooterPage__column__title {
  font-weight: 700;
  margin-bottom: 6px;
}

.FooterPage__column__content__element {
  margin-bottom: 3px;
  line-height: 18px;
  font-size: 12px;
  a {
    color: $font-color;
    &.link {
      color: $blue; 
    }
    &:hover {
      color: $blue;
      text-decoration: none;
      &.link {
        text-decoration: underline;
      }
    }
  }
}

.FooterPage__copyright__title {
  font-size: 28px;
  text-align: center;
}

.FooterPage__copyright__bottomCopyright {
  text-align: center;
  margin-bottom: 1em;
  color: #A0ACC2;
  font-size: 12px;
}


