
@import '../../../Color';
@import '../../../GlobalVariables';

#CodePage {
    padding: 0px 32px;
}

.CodePage__formBody__illustration{
  margin: auto;
  max-width: 24em;
  margin-bottom: 2em;
}

.CodePage__formBody__illustration__image {
  width: 100%;
}

.CodePage__formBody__textfield {
  max-width: 18em!important;
  text-align: center;
  margin: auto;
  display: block;
}

.CodePage__formBody__errorGlobal {
  color: $pink;
  text-align: center;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: max-height 500ms, margin-bottom 500ms,  margin-top 500ms;
  &.open {
    margin-top: 1em;
    margin-bottom: 1em;
    max-height: 90px;
  }
}

.CodePage__formBody__buttonRow {
  margin-top: 1em;
}

.CodePage__formBody__buttonRow__validate {
    margin: auto;
    display:block;
}

// --- Countdown Row ---
.CodePage__formBody__countdownRow {
  color: $green;
  text-align: center;
}

.CodePage__formBody__countdownRow__title {
  max-width: 400px;
  margin: auto;
  margin-bottom: 16px;
}

.CodePage__formBody__countdownRow__countdown {
  border-radius: 50px;
  background-color: $green-light;
  max-width: 300px;
  padding: 12px;
  margin: auto;
}

.CodePage__formBody__countdownRow__countdown__text {
  font-weight: 700;
}

.CodePage__formBody__countdownRow__countdown__subText {
  margin-top: 2px;
  font-size: 10px;
  font-weight: 600;
}

// --- OFFERS ROW ---
.CodePage__formBody__offersRow {
  margin-top: 32px;
}

.CodePage__formBody__offersRow__element {
  display: flex;
  align-items: center;
  padding: 32px 0;
}

.CodePage__formBody__offersRow__element__price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 130px;
  flex-shrink: 0;
  font-weight: 600;
}

.CodePage__formBody__offersRow__element__price__tick {
  $size: 40px;
  width: $size;
  height: $size;
  background-color: transparent;
  border-radius: 50%;
  background-image: url('../../../Pictures/illustrations/tick.png');
  background-repeat: no-repeat;
  background-size: $size;
  background-position: center;
}

.CodePage__formBody__offersRow__element__price__true {
  $fontSize: 17px;
  $color: $grey;
  font-size: $fontSize;
  color: $color;
  position: relative;
  &:after {
    content: "";
    width: 114%;
    background: $color;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-12deg) translate(-6px, 11px)

  }
  .CodePage__formBody__offersRow__element__price__euroSign {
    font-size: $fontSize/1.5;
  }
}

.CodePage__formBody__offersRow__element__price__after {
  font-weight: 700;
  font-size: 24px;
  color: $blue;
  font-style: italic;
  .CodePage__formBody__offersRow__element__price__euroSign {

  }
}

.CodePage__formBody__offersRow__element__text {
  font-weight: 700;
  font-size: 21px;
}

.CodePage__formBody__offersRow__element__text__emoji {
  width: 20px;
  position: relative;
  top: 2px;
}

.CodePage__formBody__offersRow__element__text__blue {
  color: $blue;
  display: inline;
}

.CodePage__formBody__offersRow__line {
  width: 100%;
  height: 1px;
  background: $grey-light;
}




