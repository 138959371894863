
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.RegisterOrchesterModalContent {
  .TopModalSquare {
    margin-bottom: 20px;
  }
}

.RegisterOrchesterModalContent__password__topElement {
  font-size: 30px;
}