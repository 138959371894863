
@import '../../../Color';
@import '../../../GlobalVariables';

.LoginForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.LoginForm__input {
  width: 100%;
  text-align: center;
  font-size: 16px;
  display: block;
}

.LoginForm__button {
  display: block;
  height: 47px;
}

.LoginForm__errors {
  text-align: center;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: max-height 500ms, margin-top 500ms, margin-bottom 500ms;
  color: $pink;
  font-size: 12px;
  overflow: hidden;
  &.open {
    margin-bottom: 10px;
    max-height: 6em;
  }
}
