
@import '../../../Color';
@import '../../../GlobalVariables';


.ProfileNavBar {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin: auto;
  max-width: fit-content;
  @media screen and (max-width: 430px){
    grid-column-gap: 12px;
  }
}

.ProfileNavBar__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 430px){
    width: 100px; 
  }
}

.ProfileNavBar__element__imageWrapper {
  $size: 55px;
  width: $size;
  height: $size;
  background-color: $blue-light;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 17px;
  }
}

.ProfileNavBar__element__text {
  text-align: center;
  font-size: 14px;
}

.ProfileNavBar__element__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $grey;
  font-weight: 600;
  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

.ProfileNavBar__element--selected {
  .ProfileNavBar__element__text {
    color: $blue;
  }
  .ProfileNavBar__element__imageWrapper {
    background-color: $blue;
  }
}