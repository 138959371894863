
@import "../../Color";
@import "../../GlobalVariables";

#NotFoundPage {
    text-align: center;
    width:100%;
    .field {
        margin-top: 150px;
        top:4em;
        margin-left:auto;
        margin-right:auto;
    }
    .text-404 {
        font-family:AvenirNext-bold;
        font-size:100px;
        color:$blue;
        letter-spacing:0;
        text-align:center;
    }
    .text {
        font-family:AvenirNext-medium;
        font-size:18px;
        color:$grey;
        letter-spacing:0;
        text-align:center;
    }
    button {
        width:138px;
        height:60px;
        border-radius:50px;
        color:white;
        border: none;
        outline: none;
        background-color: $blue;
    }
}
