@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.TicketingModalContent {
  h2 {
    font-size: 19px;
    font-weight: 600;
  }
}

.TicketingModalContent__content {
  text-align: center;
  padding: 0 16px 24px 16px;
}

.TicketingModalContent__priceWrapper {}

.TicketingModalContent__priceWrapper__label {
  font-size: 14px;
  color: $grey;
}

.TicketingModalContent__priceWrapper__price {
  font-weight: 700;
  font-size: 25px;
}

.TicketingModalContent__email {
  text-align: center;
  border-radius: 50px;
  background-color: $grey-xtra-light;
  color: $grey;
  border: 1px solid $grey-light;
  padding: 13px;
}


.TicketingModalContent__content__form {
  margin: auto;
  max-width: 300px;
  width: 100%;
}

.TicketingModalContent__form {}

.TicketingModalContent__form__card {
  width: 100%;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid $grey-light;
  padding: 16px;
  font-size: 16px;
  &.StripeElement--invalid {
    border-color: $pink;
  }
}

.TicketingModalContent__form__button {
  width: 100%;
  height: 55px;
}

.TicketingModalContent__error {
  color: $pink;
  font-size: 14px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms, margin-top 600ms;
  &.show {
    max-height: 100px;
    margin-top: 25px;
  }
}

.TicketingModalContent__legalWrapper {
  margin: auto;
  color: $grey;
  font-size: 12px;
  max-width: 280px;
  width: 100%;
}

.TicketingModalContent__line {
  margin: auto;
  max-width: 100px;
  width: 100%;
  background-color: $grey-light;
  height: 1px;
}

.TicketPriceElement {
  display: flex;
  align-items: center;
  border: 1px solid $blue;
  border-radius: 20px;
  padding: 12px 24px;
}

.TicketPriceElement__price {
  font-size: 20px;
  color: $blue;
  font-weight: 600;
  min-width: 60px;
}

.TicketPriceElement__price__sign {
  font-size: 14px;
}

.TicketPriceElement__text {
  font-size: 14px;
  margin-left: 12px;
  text-align: left;
}

.TicketPriceElement__text__title {
  font-weight: 600;
  flex-grow: 1;
}

.TicketPriceElement__text__description {
  color: $grey;
  font-size: 12px;
}

.TicketPriceElement-selected {
  background-color: $green-light;
  border: 1px solid $green-light;
  .TicketPriceElement__price {
    color: $green;
  }
}
