@import "../../../GlobalVariables";
@import "../../../Color";

#DefaultModal {
  @keyframes fadein{
    0% { opacity:0; }
    100% { opacity:1; }
  }

  width: 100%;
  height: 100%;
  padding: 12px;
  position: fixed;
  animation-name: fadein;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
  overflow: auto;
  top: 0px;
  box-sizing: border-box;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  .DefaultModal__modal {
    width: 100%;
    max-width: 23em;
  }

  #modal {
    z-index: 5;
    background: white;
    margin-right: auto;
    margin-left: auto;
    top: 4em;
    position: relative;
    border-radius: 20px;
    margin-bottom: 4em;

    .closeImage {
      z-index:7;
      position: absolute;
      img {
        width:20px;
        height:20px;
      }
      right: 25px;
      top:25px;
    }
  }
}