
@import "../../Color";
@import "../../GlobalVariables";

.LoadingPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
