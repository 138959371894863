@import '../../../Color';
@import '../../../GlobalVariables';

.ChallengePriceLabel__price {
  background-color: $grey-xtra-light;
  color: $font-color;
  height: 0px;
  display: flex;
  align-items: center;
  max-width: max-content;
  margin: auto;
  padding: 24px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 18px;
}

.ChallengePriceLabel__price__image {
  margin-right: 6px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
  }
}

.ChallengePriceLabel {
  &.ChallengePriceLabel-mini {
    .ChallengePriceLabel__price {
      font-size: 12px;
      height: 35px;
      padding: 18px;
      font-weight: 600;
    }
    .ChallengePriceLabel__price__image {
      img {
        width: 14px;
      }
    }
  }
}