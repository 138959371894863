
@import "../../../Color";

$dark-color:rgb(245, 248, 255);
$dark-color-border: rgb(207, 215, 255);
$dark-color-border-focus: rgb(159, 189, 255);
$secondary: $grey-light;

#TextForm {
    textarea {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 18px;
        font-size:18px;
        resize: none;
        //transition: height 100ms;
        border: 1px solid $grey-light;
        overflow-y:hidden;
        //min-height: 200px;
        border-radius: 5px;
        -webkit-appearance: none;
        &.OneLine {
            overflow-y: auto;
        }
    }

    textarea:focus {
        border: 1px solid $blue;
        outline:none;
        &.dark {
            border: 1px solid $purple!important;
        }
    }

    .dark {
        background-color: $dark-color;
        border: 1px solid $dark-color-border;
    }

    textarea::placeholder {
        color:$secondary;
        font-family: AvenirNext-Regular;
        &.dark {
            color:$dark-color-border!important;
        }
    }

    input {
        width: 100%;
        box-sizing: border-box;
        padding: 11px 18px;
        font-size:18px;
        resize: none;
        transition: height 100ms;
        border: 1px solid $secondary;
        overflow-y:hidden;
        border-radius: 50px;
        -webkit-appearance: none;
        &.OneLine {
            max-height: 55px;
            overflow-y: auto;
        }
    }

    input:focus {
        border: 1px solid $blue;
        outline:none;
        &.dark {
            border: 1px solid $purple!important;
        }
    }

    input::placeholder {
        color:$secondary;
        font-family: AvenirNext-Regular;
        &.dark {
            color:$dark-color-border!important;
        }
    }

    .disabled {
        background-color: $purple-light;
        border: 1px solid $purple-light;
        color: $grey!important;
        cursor: not-allowed;
    }

    .error {
        border: 1px solid $pink!important;
    }

    .valid {
        border: 1px solid $green!important;
    }
}

