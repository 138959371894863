
@import '../../../Color';
@import '../../../GlobalVariables';

.ProfileSettingsPage {
  max-width: 550px;
  margin: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ProfileSettingsPage__back {
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    display: block;
  }
}

.ProfileSettingsPage__subButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
