
@import '../../../Color';
@import '../../../GlobalVariables';

.TabSelector {
  padding: 0 16px;
}

.TabSelector__title {
  font-size: 38px!important;
  max-width: 660px;
  margin: auto;
  .blue {
    color: $blue;
  }
}

$TabSelector_transition_time: 2s;

.TabSelector__menu {
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
}

.TabSelector__menu__element {
  flex-shrink: 0;
  margin: 12px 0 0 12px;
  width: calc(100% + 12px);
  text-align: center;
  border-radius: 50px;
  border: 1px solid $blue;
  color: $blue;
  height: 50px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 500ms, color 500ms;
  font-weight: 700;
  &:hover {
    color: white;
    background-color: $blue;
  }
  &.selected {
    background-color: $blue;
    color: white;
  }
  @media screen and (max-width: 600px){
    height: 40px;
    width: 115px;
  }
}

.TabSelector__content {
  position: relative;
  display: flex;
}

.TabSelector__content__element {
  width: 100%;
  &.visible {
    -webkit-animation: fadeIn $TabSelector_transition_time;
    animation: fadeIn $TabSelector_transition_time;
  }
  &.hidden {
    -webkit-animation: fadeOut $TabSelector_transition_time;
    animation: fadeOut $TabSelector_transition_time;
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  99% { 
    opacity: 1;
  }
  100% {
    display: block;
  }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  99% { 
    opacity: 0;
  }
  100% {
    display: none;
  }
}




