
@import '../../../Color';
@import '../../../GlobalVariables';

.TestimonialSignature {
  display: flex;
  align-items: center;
}

.TestimonialSignature__imageWrapper {
  flex-shrink: 0;
  width: 80px;
}

.TestimonialSignature__imageWrapper__image {
  $size: 60px;
  width: $size;
  height: $size;
  border-radius: 50%;
  object-fit: cover;
}

.TestimonialSignature__textWrapper {
  flex-grow: 1;
}

.TestimonialSignature__textWrapper__name {
  font-weight: 700;
}

.TestimonialSignature__textWrapper__job {
  font-size: 14px;
  color: $grey;
}




