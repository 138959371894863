
@import "../../../Color";
@import "../../../GlobalVariables";

#ChallengeHourRange {
    display: inline-block;
    .ChallengeHourRange__dateContainer {
        display: flex;
        align-items: center;
        font-size:16px;
        font-weight: 300;
        .ChallengeHourRange__dateContainer__clockIco {
            position:relative;
            width: 17px;
            margin-right: 6px;
        }
        .ChallengeHourRange__dateContainer__hours {
            top: 1px;
            position: relative;
            font-weight: 600;
        }
        .ChallengeHourRange__dateContainer__timezone {
            font-size: 12px;
        }
    }
    &.small {
        .ChallengeHourRange__dateContainer {
            .ChallengeHourRange__dateContainer__clockIco {
                width: 14px;
            }
            font-size: 14px;
            .ChallengeHourRange__dateContainer__timezone {
                font-size: 10px;
            }
        }
    }
}