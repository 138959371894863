
@import '../../../Color';
@import '../../../GlobalVariables';

.ProfileForm {
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  form {
    width: 100%;
  }
}


.ProfileForm__imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ProfileForm__imageWrapper__image {}

.ProfileForm__imageWrapper__image__imageDiv {
  $size: 134px;
  width: $size;
  height: $size;
  border-radius: 50%;
}

.ProfileForm__imageWrapper__image__imageDiv__profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.ProfileForm__imageWrapper__errorWrapper {
  font-size: 12px
}

.ProfileForm__imageWrapper__errorWrapper__errorImage {
  color: $pink;
}

.ProfileForm__imageWrapper__errorWrapper__infoImage {
  color: $grey;
  &.clickable {
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.ProfileForm__jobAndEnterprise {
  display: flex;
  @media screen and (max-width: 500px){
    flex-direction: column;
  }
}

.ProfileForm__jobAndEnterprise__separator {
  width: 18px;
}

.ProfileForm__email {
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  color: $grey;
  background-color: $purple-light;
  border-radius: 50px;
  height: 55px;
  width: 100%;
  cursor: not-allowed;
}

.ProfileForm__skills__title {
  font-weight: 600;
  text-align: center;
  span {
    font-weight: 500;
    color: $grey;
    font-size: 14px;
  }
}

.ProfileForm__buttons {
  display: flex;
  justify-content: center;
}

.ProfileForm__buttons__button {
  display: block;
  padding: 18px 66px 21px
}

.ProfileForm__input {
  width: 100%;
  text-align: center;
  font-size: 18px;
  display: block;
}

.ProfileForm__button {
  display: block;
  height: 47px;
}

.ProfileForm__errors {
  text-align: center;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: max-height 500ms, margin-top 500ms, margin-bottom 500ms;
  color: $pink;
  font-size: 12px;
  overflow: hidden;
  &.open {
    margin-bottom: 10px;
    max-height: 6em;
  }
}
