
@import "../../../Color.scss";
@import "../../../GlobalVariables.scss";

.loading-spinner{
    img {
        width: 62px;
        height: auto;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}
