
@import '../../Color';
@import '../../GlobalVariables';

#Home {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    h1, h4, h2 {
        text-align: center;
    }

    .mission {
        font-weight: 400!important;
    }

    header {
        display: flex;
        justify-content: normal;
        height: 60px;
        position: absolute;
        @media screen and (max-width: 900px) {
            height: 75px;
        }
        left: 0;
        right: 0;
        z-index: 100;
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            flex-shrink: 0;
            img {
                position: absolute;
                width: 24px;
                height: auto;
                left: 33px;
                top: 13px;
                flex-shrink: 0;
                &.logoImageMobile {
                    visibility: hidden;
                    @media screen and (max-width: 900px) {
                        visibility: visible;
                    }
                }
                &.logoImage {
                    visibility: visible;
                    @media screen and (max-width: 900px) {
                        visibility: hidden;
                    }
                }
            }
        }
        .links {
            margin-left: 40px;
            flex-grow: 1;
            a {
                color: #344564;
                font-weight: 500;
                @media screen and (max-width: 900px) {
                    color: white;
                    font-weight: 600;
                }
                line-height: 62px;
                font-size: 14px;
                text-decoration: none;
                margin-right: 50px;
                &:hover {
                    color: $blue;
                }
            }
        }
        .contact {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 180px;
            margin-right: 16px;
            @media screen and (max-width: 1020px) {
                visibility: hidden;
            }

            @media screen and (min-width: 2230px) {
                visibility: hidden;
            }
            a {
                width: 100%;
                font-weight: 700;
                color: white;
                height: 40px;
                font-size: 14px;
                background-color: transparent;
            }
        }
    }

    h1 {
        font-family: 'Muli', sans-serif;
        font-weight: 700;
        font-size: 40px;
        @media screen and (max-width: $break-small) {
            font-size:25px;
        }
    }

    .orange {
        color: $orange-alt;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        color: $grey;
        font-size: 14px;
        font-weight: 400!important;
        text-align: center;
    }

    h4 {
        margin-bottom:2em;
        font-family: 'Muli', sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: white;
        .orange {
            font-weight: bold;
        }
    }

    p {
        font-size: 20px;
        line-height: 30px;
    }

    .logos {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-around;
        max-width: 260px;
        width: 100%;
        .logoImg {
            display: inline-block;
            height: 90px;
            object-fit: contain;
            border-radius: 30px;
            width: 90px;
            padding: 18px;
            background: white;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
        }
    }

    .form {
        .baseButton {
            width:100%;
            max-width: 360px;
            display: block;
            padding: 0px 50px;
            font-size: 18px;
            font-family: 'Muli', sans-serif;
            font-weight: 600;
            height: 70px;
            margin: auto;
        }
    }
    .name {
        h1 {
            text-align: left !important;
            margin: 20px 20px;
            font-size: 30px;
            @media screen and (max-width: $break-small) {
                visibility:hidden;
            }
        }
        color: white;
    }

    .formSide {
        height: 100%;
        .contentTop {
            height: 100%;
            @media screen and (max-width: $break-small) {
                height: 850px;
            }
            display: flex;
            margin-top: -4em;
            position: relative;
            .arrow {
                position: absolute;
                $bottom: 40px;
                bottom: $bottom;
                left: calc(50% - 50px);
                width: 100px;
                height: 100px;
                @media screen and (max-width: $break-small) {
                    visibility: hidden;
                    height: 0;
                    overflow: hidden;
                }
                display: flex;
                align-items: center;
                img {
                    display: block;
                    margin: auto;
                    width: 30px;
                    position: relative;
                    animation-name: bouncing;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-fill-mode: both;

                    @keyframes bouncing {
                        0% {top: 0;}
                        50% {top: 20px;}
                        100% {top: 0;}
                    }
                }

                img {
                    width:30px;
                    height:auto;
                }
            }
            .welcomeSentence {
                max-width: 42em;
                margin: auto;
                padding: 0em 30px;
                color: white;
            }
        }
        .blue {
            color:$blue;
        }
        .content {
            background-color: white;
            overflow-x: hidden;
            .contentPlace {
                padding-bottom: 32px;
            }
        }
        .logo {
            visibility:hidden;
            height:0px;
            @media screen and (max-width: $break-small) {
                visibility:visible;
                height:auto;
            }
            text-align: center;
            .logoImg {
                display: inline-block;
                height: 60px;
                object-fit: contain;
                border-radius: 30px;
                width: 60px;
                padding: 18px;
                background: white;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
            }
        }
        .bottomBar {
            visibility:hidden;
            @media screen and (max-width: $break-small) {
                visibility:visible;
            }
            position: fixed;
            bottom: -2em;
            left: 0;
            right: 0;
            height: 4em;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .logoBar {
        width: 100%;
        position: absolute;
        @media screen and (max-width: $break-small) {
            visibility:hidden;
        }
        .logoTopLeft {
            width: 30px;
            margin: 30px;
            transition-property: width, margin;
            transition-duration: 200ms;
            @media screen and (max-width: $break-small) {
                margin: 12px;
                width: 20px;
                margin-bottom: 7px;
            }
        }
    }
    .bottomCopyrigth {
        text-align: center;
        margin-bottom: 1em;
        color: #A0ACC2;
        font-size: 12px;
    }

    #FruitsGame {
        margin-top: 3em!important;
    }

    .fixedButton {
        position: fixed;
        right: 32px;
        bottom: 32px;
        width: 100px;
        height: 100px;
        transition: width 500ms, height 500ms;
        @media screen and (max-width: $break-small) {
            width: 60px;
            height: 60px;
            right: 16px;
            bottom: 16px;
        }

        border-radius: 50%;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-property: transform;
        transition-duration: 0.5s;
        img {
            width: 30%;
        }
        &:hover{
            transform: scale(1.1, 1.1);
        }

        &:active{
            transform: scale(1.05, 1.05);
        }
    }
    #canvasParticles {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        pointer-events: none;
    }

    .tickRow {
        min-width: 1px;
        display: inline-flex;
        align-items: center;
        //flex-basis: content;
        @media screen and (max-width: 900px) {
            //flex-direction: column;
            justify-content: center;
        }
        .tickPicture {
           flex-shrink: 0;
           background-color: $green-light;
           background-image: url('../../Pictures/icons/icon_true_green.svg');
           background-position: center;
           background-repeat: no-repeat;
           background-size: 12px;
           width: 40px;
           height: 40px;
           border-radius: 50%;
        }
        .tickText {
            position: relative;
            font-size: 18px;
            text-align: left;
            flex-shrink: 1;
            line-height: 30px;
            margin-left: 12px;
            @media screen and (max-width: 900px) {
                //text-align: center;
                //margin-bottom: 1em;
            }
        }
    }
}

.home__features__button {
    padding: 18px 42px 20px 42px;
}

.home__quoteText {
    font-size: 20px;
    text-align: left;
    background-image: url('../../Pictures/illustrations/quote.png');
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: top left;
    position: relative;
    font-style: italic;
    $offset: 16px;
    left: -$offset;
    padding: $offset;
    line-height: 28px;
}

.home__hashlink__feature {
    display: block;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    button {
        max-width: 350px;
        margin: auto;
    }
}

.home__title__small {
    font-size: 25px!important;
}

