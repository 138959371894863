@import "../../../Color";
@import "../../../GlobalVariables";

.ChallengeNavBar {
  background-color: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 21px;
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 500ms;
    @media screen and (max-width: 1015px) {
      background-color: white;
    }
  }
}

.ChallengeNavBar_logoContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.ChallengeNavBar_logoContainer__logoWrapper {
  display: block;
  padding: 0 12px;
  transition: padding 500ms;
  @media screen and (max-width: 1055px) {
    padding: 0 12px 0 0;
  }
  box-sizing: border-box;
}

.ChallengeNavBar_logoContainer__logoWrapper__logo {
  display: block;
  width: 23px;
  height: 40px;
  background-image: url("../../../Pictures/Logos/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ChallengeNavBar_logoContainer__mixlabWrapper {
  font-weight: 800;
  transition: color 500ms;
  color: $font-color;
  &:hover {
    text-decoration: none;
  }
}

.ChallengeNavBar__content {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
}

.ChallengeNavBar__content__profileImageRow {
  $size: 38px;
  display: block;
  height: $size;
  width: $size;
  border-radius: 50%;
  border: 4px solid $orange;
  &.noImage {
    $size: 42px;
    height: $size;
    width: $size;
    border-width: 0px;
  }
}

.ChallengeNavBar__content__profileImageRow__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ChallengeNavBar__content__element {
  &.login {
    color: $grey-dark;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ChallengeNavBar__content__element__mobile {
  padding: 10px 0px 12px 31px;
}
