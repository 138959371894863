
@import '../../../../Color';
@import '../../../../GlobalVariables';

.PasswordForceIndicator {
  display: flex;
  width: 100%;
  max-width: 250px;
  margin: auto;
  margin-top: 1em;
}

.PasswordForceIndicator__dotContainer {
  padding: 0 7px;
  flex-grow: 1;
}

.PasswordForceIndicator__dotContainer__dot {
  height: 8px;
  border-radius: 50px;
  background-color: $grey-xtra-light;
  transition-property: background-color;
  transition-duration: 200ms;
}

.PasswordForceIndicator.red {
  .PasswordForceIndicator__dotContainer {
    .PasswordForceIndicator__dotContainer__dot.actif {
      background-color: $pink;
    }
  }
}

.PasswordForceIndicator.orange {
  .PasswordForceIndicator__dotContainer {
    .PasswordForceIndicator__dotContainer__dot.actif {
      background-color: $orange;
    }
  }
}

.PasswordForceIndicator.blue {
  .PasswordForceIndicator__dotContainer {
    .PasswordForceIndicator__dotContainer__dot.actif {
      background-color: $blue;
    }
  }
}

.PasswordForceIndicator.green {
  .PasswordForceIndicator__dotContainer {
    .PasswordForceIndicator__dotContainer__dot.actif {
      background-color: $green;
    }
  }
}