
@import '../../../Color';
@import '../../../GlobalVariables';

$sizeLogo: 70px;

.ProfilePage {
  max-width: 550px;
  margin: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 64px;
}

.ProfilePage__content {
  width: 100%;
}

.ProfilePage__content__empty {
  color: $grey;
}

.ProfilePage__content__title {
  font-size: 30px;
  font-weight: 700;
}

.ProfilePage__content__subTitle {
  font-size: 14px;
  color: $grey;
  b {
    color: $font-color;
  }
}

.ProfilePage__content__subTitle__edit {
  white-space: nowrap;
}

.ProfilePage__content__communitiesWrapper__communities__community {
  display: flex;
  margin-top: 16px;
  &.bordered {
    border-bottom: 1px solid $grey-xtra-light;
    padding-bottom: 16px;
  }
  &.closed {
    opacity: 0.5;
  }
}

.ProfilePage__content__communitiesWrapper__communities__community__imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $blue-light;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: relative;
  img {
    width: $sizeLogo;
    height: $sizeLogo;
    border-radius: 50%;
    background-color: white;
    object-fit: cover;
  }
}

.ProfilePage__content__communitiesWrapper__communities__community__imageWrapper__notEnroled {
  position: absolute;
  $size: 15px;
  width: $size;
  height: $size;
  top: -$size/4;
  right: -$size/4;
  background-color: $blue;
  border-radius: 50%;
  border: 4px solid white;
}

.ProfilePage__content__communitiesWrapper__communities__community__informations {
  flex-grow: 1;
  margin-left: 20px;
}

.ProfilePage__content__communitiesWrapper__communities__community__informations__displayName {
  font-size: 18px;
  font-weight: 700;
}

.ProfilePage__content__communitiesWrapper__communities__community__informations__url {
  font-size: 14px;
  color: $grey;
}

.ProfilePage__content__communitiesWrapper__communities__community__informations__eventCount {
  font-size: 14px;
  color: $blue;
}

.ProfilePage__content__communitiesWrapper__communities__community__join {
  margin-left: 32px;
  @media screen and (max-width: 500px){
    margin-left: 12px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProfilePage__content__communitiesWrapper__communities__community__join__notEnroled {
  padding: 10px 38px 12px 39px;
}

.ProfilePage__content__communitiesWrapper__communities__community__buttonDesktop {
  @media screen and (max-width: 500px){
    display: none;
  }
}

.ProfilePage__content__communitiesWrapper__communities__community__buttonMobile {
  $size: 40px;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $blue;
  background-image: url('../../../Pictures/icons/icon_playWhite.png');
  background-position: 14px 13px;
  background-size: 14px;
  background-repeat: no-repeat;
  @media screen and (min-width: 500px){
    display: none;
  }
}

.ProfilePage__content__eventsWrapper__events__event__informations__title {
  max-width: 350px;
  display: block;
}

.ProfilePage__content__eventsWrapper__events__event {
  display: flex;
  color: $font-color;
  margin-top: 16px;
  padding-bottom: 16px;
  &:hover {
    text-decoration: none;
    .ProfilePage__content__eventsWrapper__events__event__informations__title {
      color: $blue;
    }
  }
  &.bordered {
    border-bottom: 1px solid $grey-xtra-light;
  }
}

.ProfilePage__content__eventsWrapper__events__event__dateAndLogo {
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px){
    margin-right: 12px;
  }
}

.ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__day {
  color: $pink;
  font-size: 25px;
  font-weight: bold;
}

.ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__month {
  font-weight: 400;
  margin-top: -5px;
  text-transform: uppercase;
}

.ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $sizeLogo;
  height: $sizeLogo;
  border-radius: 50%;
  border: 1px solid $grey-light;
  &.ended {
    border: none;
    background-color: $grey-xtra-light;
    .ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__day {
      color: $font-color;
    }
  }
  &.canceled {
    border: none;
    background-color: $pink-light;
    .ProfilePage__content__eventsWrapper__events__event__dateAndLogo__dateWrapper__day {
      color: $pink;
    }
  }
}

.ProfilePage__content__eventsWrapper__events__event__dateAndLogo__logo {
  width: $sizeLogo;
  height: $sizeLogo;
  position: relative;
  left: -17px;
  background-color: white;
  border-radius: 50%;
  @media screen and (max-width: 500px){
    display: none;
  }
}

.ProfilePage__content__eventsWrapper__events__event__informations {
  flex-grow: 1;
}

.ProfilePage__content__eventsWrapper__events__event__informations__communityName {
  font-size: 14px;
  font-weight: 700;
}

.ProfilePage__content__eventsWrapper__events__event__informations__hour {
  font-size: 14px!important;
  font-weight: 600;
}

.ProfilePage__content__eventsWrapper__events__event__status {
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px){
    display: none;
  }
}