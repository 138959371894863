

.LanguageDetector {
  $size: 30px;
  display: grid;
  grid-template-columns: $size $size;
  grid-column-gap: 32px;
  justify-content: center;
}

.LanguageDetector__element {
  img {
    width: 100%;
    height: auto;
  }
}