
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.AttendingConfirmedModalContent {
}

.AttendingConfirmedModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.AttendingConfirmedModalContent__content__sentence {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
}

.AttendingConfirmedModalContent__content__ticket__subtitle {
  color: $grey;
  font-size: 12px;
  text-align: center;
}

.AttendingConfirmedModalContent__content_element {
  text-align: center;
}

.AttendingConfirmedModalContent__content_element_title {
  font-size: 14px;
  color: $grey;
}

.AttendingConfirmedModalContent__content_element_text {
  word-break: break-word;
}

.AttendingConfirmedModalContent__content__button {
  width: 100%;
}