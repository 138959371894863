
@import '../../../Color';
@import '../../../GlobalVariables';

#CreateCommunity {
    padding: 0px 16px;
    text-align: center;
}


