
@import '../../../Color';
@import '../../../GlobalVariables';

#Customers {
  margin:auto;
  max-width: 720px;
  padding: 0px 16px;
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .container {
    .testisContainer {
      display:flex;
      justify-content: space-around;
      @media only screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
      margin: auto;
      width: 100%;
      img {
        height: 120px;
        width: auto;
      }
    }
  }
}


