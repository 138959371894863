
$blue: #357cfb;
$blue-dark: #1A1894;
$blue-gradient: linear-gradient(180deg, #2F78FF 0%, #0A60FF 100%);
$blue-alpha: rgba(53, 124, 251, 0.70);
$blue-light: rgb(234, 241, 251);
$blue-mid-light: #70a0fb;
$orange: #F7650F;
$orange-alt: #FF8F00;
$orange-light: #fceeec;
$purple: #3f46ad;
$purple-pink:#CF00FF;
$purple-light: #EFF0FE;
$purple-xtra-light: #F6F7F9;
$red: #c72622;
$red-light: rgb(255, 234, 234);
$green:#23d188;
$green-dark: #00C353;
$green-light: #dcf9ee;
$yellow:#f8e71c;
$yellow-light: #FFFAEE;
$grey-light:#E4E8EE;
$grey-xtra-light: #F1F2F5;
$grey:#A0ACC2;
$grey-min-dark: #a0acc2;
$grey-dark:#4a4a4a;
$pink:#FF3465;
$pink-light: #fff8f9;

$font-color: #344564;
$font-color-light: #A0ACC2;