
.baseButton {
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: $blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  .arrow {
    width: 9px;
    position: relative;
    top: 3px;
  }
}