
@import '../../../Color';
@import '../../../GlobalVariables';

.GodPage {
  max-width: 800px;
  margin: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 64px;
}
