
@import '../../../Color';
@import '../../../GlobalVariables';

.PlanSelection {
    padding: 0px 20px;
}

.PlanSelection__formBody {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    h2 {
        text-align: center;
        font-size: 36px;
        font-weight: 800;
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
}

.PlanSelection__offer {
    margin: auto;
    display: flex;
    align-items: center;
    background-color: $blue-light;
    border-radius: 20px;
    padding: 15px 25px;
    max-width: 700px;
    width: 100%;
    box-sizing: border-box;
}

.PlanSelection__offer__icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 35px;
        height: auto;
    }
}

.PlanSelection__offer__text {
    font-size: 14px;
    margin-left: 12px;
}

.PlanSelection__plansContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.PlanSelection__plansContainer__elementWrapper {
    width: 100%;
    max-width: 310px;
    min-width: 200px;
    padding: 12px 20px;
}

.PlanSelection__plansContainer__elementWrapper__element {
    height: 375px;
    padding: 24px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    &.orange {
        background-color: #FF8F00;
        button {
            &:hover {
                background-color: white;
                color: #FF8F00;
            }
        }
    }
    &.pink {
        background-color: #FC00A2;
        button {
            &:hover {
                background-color: white;
                color: #FC00A2;
            }
        }
    }
    &.purple {
        background-color: #6E24EA;
        button {
            &:hover {
                background-color: white;
                color: #6E24EA;
            }
        }
    }
}

.PlanSelection__plansContainer__elementWrapper__element__title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.PlanSelection__plansContainer__elementWrapper__element__pricing {
    text-align: center;
    font-weight: 800;
}

.PlanSelection__plansContainer__elementWrapper__element__pricing__price {
    font-size: 80px;
    letter-spacing: 6px;
}

.PlanSelection__plansContainer__elementWrapper__element__pricing__euroSign {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
}

.PlanSelection__plansContainer__elementWrapper__element__pricing__duration {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
}

.PlanSelection__plansContainer__elementWrapper__element__features {
    text-align: center;
}

.PlanSelection__plansContainer__elementWrapper__element__features__element {
}

.PlanSelection__plansContainer__elementWrapper__element__features__element__tickWrapper__tick {
    $size: 23px;
    height: $size;
    width: $size;
    border-radius: 50%;
    background-color: $green-light;
    background-image: url('../../../Pictures/icons/icon_true_green.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36%;
}

.PlanSelection__plansContainer__elementWrapper__element__features__element__description {
    font-size: 16px;
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }

    }
}

.PlanSelection__plansContainer__elementWrapper__element__buttonWrapper {
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
}

.PlanSelection__plansContainer__elementWrapper__element__buttonWrapper__button {
    width: 100%;
    height: 50px;
}

.PlanSelection__plansContainer__elementWrapper__element__featuredWrapper {
    position: absolute;
    top: -21px;
    left: 24px;
    right: 24px;
}

.PlanSelection__plansContainer__elementWrapper__element__featureLink__icon {
    width: 12px;
}

.PlanSelection__plansContainer__elementWrapper__element__featuredWrapper__featured {
    margin: auto;
    background-color: $blue;
    border-radius: 50px;
    color: white;
    font-size: 12px;
    padding: 12px 12px;
    text-align: center;
    width: 100%;
    max-width: 150px;
}


/// Feature

.PlanSelection__features {
    padding: 0 16px;
    max-width: 1000px;
    margin: auto;
    h2, h3 {
        text-align: center;
    }
    h2 {
        font-size: 22px;
        margin-bottom: 0;
    }
    h3 {
        margin-top: 4px;
        font-weight: 500;
        font-size: 15px;
        color: $grey;
    }
}

.PlanSelection__features__container {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(3, 33% [col-start]);
    @media screen and (max-width: 950px){
        grid-template-columns: repeat(2, 50% [col-start]);
    }
    @media screen and (max-width: 500px){
        grid-template-columns: repeat(1, 100% [col-start]);
    }
    grid-row-gap: 12px;
    grid-column-gap: 12px;
}

.PlanSelection__features__container__element {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 12px;
}

.PlanSelection__features__container__element__imageWrapper {
    display: flex;
    align-items: center;
}

.PlanSelection__features__container__element__imageWrapper__container {
    background-color: $blue-light;
    $size: 60px;
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 20px;
        border: none;
    }
    &.premium {
        background-color: #F2E6FF;
    }
}

.PlanSelection__features__container__element__text {
    margin-left: 12px;
}

.PlanSelection__features__container__element__text__name {
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: 600;
}

.PlanSelection__features__container__element__text__name__premium {
    width: 12px;
    position: relative;
    top: 1px;
}

.PlanSelection__features__container__element__text__subText {
    font-size: 13px;
    font-weight: 500;
    color: $grey;
}

.PlanSelection__formBody__title {
    max-width: 600px;
    margin: auto;
}

.PlanSelection__formBody__sub {

}

.PlanSelection__formBody__sub__title {
    margin: auto;
    text-align: center;
    font-size: 16px!important;
    max-width: 520px;
}

.PlanSelection__formBody__sub__buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PlanSelection__formBody__sub__buttonWrapper__link {

}

