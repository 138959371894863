@import 'Color';
@import 'Buttons';

html {padding:0; margin:0;}
html {
  body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    color: $font-color;
    font-family: 'Muli', sans-serif!important;
  }
}

#App {
  color: $font-color;
}

.App__content {

}

.emoji {
  vertical-align: middle;
  height: auto;
  width: 20px;
}

#root, #App, body, #Home, html {
  scroll-behavior: smooth;
  min-height: 100%;
}

.clickable {
  cursor: pointer;
}

.formBody {
  max-width: 30em;
  width: 100%;
  margin: auto;
  margin-top: 50px;
&.formBody__bigger {
    max-width: 36em;
  }
  h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 4px;
  }
  h3 {
    color: $grey;
    font-size: 14px;
    text-align: center;
    font-weight: normal;

  }
  .label {
    &.center {
      text-align: center;
    }
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }
  .subLabel {
    color: $grey;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 3px;
    &.noErrorLabel {
      margin-bottom: 13px;
    }
    a {
      color: $blue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .formBody__button {
    background-color: $blue;
    min-height: 50px;
    border-radius: 50px;
    color: white;
    border: none;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    outline: none;
    &:active {
      background-color: $blue-alpha;
    }
  }
  .errorGlobal {
    display: block;
    text-align: center;
    color: $pink;
    max-height: 0px;
    min-height: 0px;
    transition: margin-top 500ms, max-height 500ms;
    overflow: hidden;
    &.open {
      margin-top: 20px;
      max-height: 300px;
      min-height: 20px;
    }
  }
  .miniloader {
    width: 10px;
  }
  .imageSelection {
    cursor: pointer;
  }
  #TextForm {
    margin-bottom: 5px;
    text-align: center;
  }
  .informationsViewer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4em;
    height:auto;
    border-radius: 50px;
    background-color: $blue-light;
    color: $blue;
    flex-grow: 1;
    margin-top: 1em;
    transition-property: height, min-height, opacity;
    transition-duration: 500ms;
    overflow: hidden;
    opacity: 1;
    &.hidden {
      min-height: 0em;
      height:0;
      opacity: 0;
    }
    //div {
    //  flex-shrink: 1;
    //  word-wrap: break-word;
    //}
    &.valid {
      background-color: $green-light;
      color: $green;
    }
    &.error {
      background-color: $pink-light;
      color: $pink;
    }
  }
  .errorLabel {
    font-size: 11px;
    text-align: right;
    display: block;
    min-height: 15px;
    margin-bottom: 3px;
    .error {
      color: $pink;
    }
    .info {
      color: $grey;
    }

  }
  .errorText {
    color: $pink;
    font-size: 14px;
  }

  button {
    cursor: pointer;
    &:focus {
      outline: none;
    }
    height: 45px;
    padding: 0 42px;
    border-radius: 35px;
    font-size: 15px;
    background-color: $blue;
    color: white;
    border: none;
    cursor: pointer;
  }
  .navigation {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
    button {
      &:focus {
        outline: none;
      }
      height: 60px;
      width: 170px;
      border-radius: 35px;
      font-size: 15px;
      cursor: pointer;
    }
    .back {
      background-color: transparent;
      border: none;
      color: $grey;
    }
    .next {
      background-color: $blue;
      color: white;
      border: none;
    }
  }
}

#LoadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    width: 50px;
    height: 50px;
    display: block;
  }
}

.xs-top {
  margin-top: 4px;
}

.s-top {
  margin-top: 8px;
}

.m-top {
  margin-top: 16px;
}

.ml-top {
  margin-top: 25px;
}

.l-top {
  margin-top: 32px;
}

.xl-top {
  margin-top: 64px;
}

.xxl-top {
  margin-top: 105px;
}

.xs-bottom {
  margin-bottom: 6px;
}

.s-bottom {
  margin-bottom: 8px;
}

.m-bottom {
  margin-bottom: 16px;
}

.l-bottom {
  margin-bottom: 32px;
}

.xl-bottom {
  margin-bottom: 64px;
}

.xxl-bottom {
  margin-bottom: 105px;
}

.text-center {
  text-align: center;
}

input[type=text], input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 22px 17px 22px;
  border: 1px solid $grey-light;
  border-radius: 50px;
  box-sizing: border-box;
  &.error {
    border: 1px solid $pink;
  }
  &:focus {
    outline: none;
    border-color: $blue;
  }
  &::placeholder {
    color: $grey-light;
  }
}

button {
  font-family: 'Muli', sans-serif!important;
  &:focus {
    outline: none;
  }
  padding: 10px 31px 12px 31px;
  border-radius: 50px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  &.big {
    padding: 24px 65px 25px 65px;
    font-size: 17px;
  }
  &:hover {
    &.text {
      text-decoration: underline;
    }
  }
  &.blue {
    background-color: $blue;
    color: white;
    &.text {
      background-color: transparent;
      color: $blue;
    }
    &.light {
      background-color: transparent;
      border: 1px solid $blue;
      color: $blue;
    }
  }
  &.rainbow {
    background-image: url('./Pictures/backgrounds/bg_rainbow_button.png');
    background-repeat: no-repeat;
    background-size: 100%;
    color: white;
  }
  &.destructive {
    background-color: $pink;
    color: white;
    &.text {
      background-color: transparent;
      color: $pink;
    }
  }
  &.green {
    color: white;
    background-color: $green;
  }
  &.greenLight {
    color: $green;
    background-color: $green-light;
  }
  &.pink {
    color: $yellow;
    background-color: $pink;
    &.text-white {
      color: white;
    }
  }
  &.pinkLight {
    color: $pink;
    background-color: $pink-light;
    &.bordered {
      border: 1px solid $pink;
    }
    &:hover {
      color: white;
      background-color: $pink;
    }
    &:disabled {
      &:hover {
        color: $pink;
        background-color: $pink-light;
      } 
    }
  }
  &.grey {
    color: $grey;
    background-color: $grey-light;
    &.text {
      background-color: transparent;
      &:hover {
        color: $blue;
      }
    }
  }
  &.back {
    color: $grey;
    background-color: transparent;
    &:hover {
      color: $blue;
    }
  }
  &.white {
    background: transparent;
    &.border {
      border: 1px solid white;
      color: white;
    }
  }
  &:disabled {
    cursor: default;
  }
}

a, .link {
  cursor: pointer;
  color: $blue;
  text-decoration: none;
  &.invisible {
    color: $font-color;
  }
  &:hover {
    text-decoration: underline;
    &.invisible {
      color: $blue;
      text-decoration: none;
    }
  }
}

span {
  &.blue {
    color: $blue;
  }
}