
@import '../../../Color';
@import '../../../GlobalVariables';

.TopModalSquare {
  background: url('../../../Pictures/backgrounds/rainbow_background.png') no-repeat;
  background-size: cover;
  width: 100%;
  height: 170px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  display:flex;
  align-items: center;
  justify-content: center;
  color: white;

  box-sizing: border-box;

  padding: 32px;

  text-align: center;
}