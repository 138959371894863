@import "../../../Color";
@import "../../../GlobalVariables";

#SkillsForm {
    text-align: left;
    width:100%;
    .stackName {
        display: inline-block;
        padding: 12px 20px;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        border: 1px solid $blue-light;
        background-color: $blue-light;
        border-radius: 50px;
        margin-right: 16px;
        color:$blue;
        .cross {
            margin-left:0.5em;
            background: no-repeat url('../../../Pictures/icons/icon_cross.svg') ;
            display: inline-block;
            width: 11px;
            height: 11px;
        }
    }

    .placeholder {
        color:$grey-light;
        font-size:18px;
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        top: 17px;
        left: 10px;
    }

    .stackSearch {
        transition-property: background-color;
        transition-duration: 0.3s;
        .noSkillsRow {
            display: flex;
            justify-content: center;
            align-items: center;
            .no-skill {
            }
            .add {
                display:inline-block;
                background-color: $blue;
                border-radius: 3px;
                background-image: url('../../../Pictures/icons/AddButton-white.svg');
                padding: 5px;
                width: 41px;
                height: 41px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px;
                margin-top: 12px;
                margin-bottom: 12px;
                margin-right: 15px;
            }
        }
    }

    .stackSearch:hover{
        background-color: $blue-light;
    }

    .stackSearchContainer {
        overflow: hidden;
        max-height: 0em;
        transition-property: max-height, height;
        transition-duration: 0.5s;
        .stackName {
            position: relative;
        }
        &.open {
            transition-property: max-height, height;
            transition-duration: 0.5s;
            max-height: 15em;
            overflow: auto;
        }
        &.close{
            height:0px;
        }
    }

    .fireField {
        text-align: right;
        span {
            position:relative;
            top: 23px;
            right:10px;
        }
        img {
            width: 16px;
            margin-right: -0.2em;
            background-color: #fceeec;
            padding: 4px;
            border-radius: 100%;
            height: 16px;
        }
    }

    .stackInput:focus-within {
        border: 1px solid $blue;
    }

    .stackInput {
        border-radius: 30px;
        cursor: text;
        border: 1px solid $grey-light;
        width: 100%;
        padding: 0px 8px;
        position: relative;
        box-sizing: border-box;
        .stackName:hover {
            .cross {
                background-image: url('../../../Pictures/icons/icon_cross.svg');
            }
        }

        .stackGroup {
            display: inline-block;
            min-height: 58px;
            max-width: 100%;
            input {
                max-width: 100%;
                font-size:18px;
                margin-top: 0.1em;
                height: 3em;
                background-color: transparent;
                border-radius: 5px;
                border: none;
                max-width: 100%;
                min-width: 48px;
                width: 48px;
            }

            input:focus {
                outline: none;
            }

            .hiddenInput {
                height:0px;
            }
        }
    }

}

#NewSkillModal {
    padding:2em;
    padding-bottom: 0;
    .NewSkillModal__text {
        margin-top: 1.5em;
        font-size: 18px;
    }
    .NewSkillModal__buttons {
        display: flex;
        flex-direction: column;
        button {
            width:100%;
            border-radius:50px;
            height:50px;
            &.add {
                background-color: $blue;
                color:white;
            }
            &.back {
                margin-top: 8px;
                background-color: transparent;
                color:$grey;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
    .bigLogo {
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .blue {
        color:$blue;
    }
    .error {
        text-align: center;
        border-radius:4px;
        border:none;
        width:100%;
        color:$pink;
        padding:16px;
        height:auto;
    }
    .success {
        text-align: center;
        border:none;
        height:auto;
        background-color: white;
        color:$green;
        padding:16px;
    }
}


