
@import '../../../Color';
@import '../../../GlobalVariables';

.LoginMagicPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.LoginMagicPage__gif {
  $size: 150px;
  width: $size;
  height: $size;
  border-radius: 50%;
  object-fit: cover;
}

.LoginMagicPage__error {
  color: $pink;
}

.LoginMagicPage__subtext {
  max-width: 20em;
  text-align: center;
  &.valid {
    color: $green;
  }
}

.LoginMagicPage__button {
  height: 50px;
}
