
@import '../../../Color';
@import '../../../GlobalVariables';

#PaymentInformations {
    padding: 0px 16px;
    .StripeElement {
        box-sizing: border-box;
        height: 60px;
        padding: 18px 18px;
        font-family: Muli;
        border: 1px solid $grey-light;
        border-radius: 50px;
        background-color: white;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        input.InputElement.is-empty.Input {
            font-size: 18px!important;
            height: 49px!important;
        }
    }

    .StripeElement--focus {
        border-color: $blue;
    }

    .StripeElement--invalid {
        border-color: $pink;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }

    .priceCalculation {
        display: flex;
        flex-direction: column;
        .row {
            margin-top: 1em;
            display: flex;
            :first-child {
                max-width: 71%;
                .informations {
                    font-size: 10px;
                    margin-top: -1px;
                    display: block;
                    color: #A0ACC2;
                    text-align: left;
                }
            }
            :last-child {
                text-align: right;
                flex-grow: 1;
            }
            &.big {
               font-size: 25px;
               .informations {
                    font-size: 10px;
                    margin-top: -1px;
                    position: relative;
                    top: -14px;
                    color: #A0ACC2;
                }
            }
        }
        .line {
            margin-top: 1em;
            height: 0px;
            width: 100%;
            border: 1px solid $grey-light;
        }
    }
    .CGVcontainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        color: $grey;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .label {
            position: relative;
            top: 2px;
        }
        a {
            color:$blue;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark:after {
                display: block;
            }
            &:checked ~ .checkmark {
                background-color: $blue;
                border: 1px solid $blue;
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: transparent;
            border-radius: 5px;
            border: 1px solid $grey;
            &.error {
                border-color: $pink;
            }
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .CGVcontainer:hover input ~ .checkmark {
        background-color: $blue-light;
    }
    .CGVcontainer:hover input:checked ~ .checkmark {
        background-color: $blue;
    }

    .navigationButtons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height:5em;
        margin-top: 20px;
        .buttonContainer {
            flex-grow: 1;
            width: 100%;
        }
        button {
            outline: none;
            height: 60px;
            width: 100%;
            border-radius: 50px;
            border: none;

            font-size: 18px;
        }
        .buyButton {
            color: white;
            background-color: $green-dark;
            margin-bottom: 1em;
        }

        .back {
            color: $grey-light;
            background-color: transparent;
        }
        .loading {
            display: block;
            width:3em;
            margin:auto;
        }
    }
    .modal3DSecureContainer {
        z-index: 5;
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        overflow: auto;
        .modal3DSecure {
            background-color: white;
            width: 100%;
            height: auto;
            max-width: 35em;
            margin: auto;
            border-radius: 10px;
            margin-top: 100px;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
            padding: 20px;
            .threedsecure {
                width: 100%;
                height: 400px;
                overflow: hidden;
                border-radius: 10px;
                iframe {
                    border: none;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        button {
            margin: auto;
            margin-top: 1em;
            margin-bottom: 1em;
            width: 200px;
            font-size: 16px;
            font-weight: 500;
            height: 60px;
            cursor: pointer;
        }
    }
}

.PaymentInformations__formBody__title {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.PaymentInformations__formBody__title__icon {
    display: flex;
    align-items: center;
    img {
        display: block;
        width: 35px;
        height: auto;
    }
}

.PaymentInformations__formBody__title__text {
    font-size: 32px;
    font-weight: 700;
    margin-top: 10px;
}

.PaymentInformations__offerWrapper__title {
    
}

.PaymentInformations__offerWrapper__content {
    border-radius: 10px;
    background-color: $green-light;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 15px 20px;
    box-sizing: border-box;
}

.PaymentInformations__offerWrapper__content__iconWrapper__icon {
    $size: 30px;
    background-color: $green;
    width: $size;
    height: $size;
    background-image: url('../../../Pictures/icons/icon_true_white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35%;
    border-radius: 50%;
}

.PaymentInformations__offerWrapper__content__iconWrapper__text {
    margin-left: 15px;
    font-size: 13px;
}


