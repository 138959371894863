
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.ChallengeErrorModal {
}

.ChallengeErrorModal__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.ChallengeErrorModal__content {
  text-align: center;
  padding: 20px;
}

.ChallengeErrorModal__content__text {
  color: $pink;
}
