
@import '../../../Color';
@import '../../../GlobalVariables';

#IntroductionMobile {
  @media only screen and (min-width: 901px) {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin-top: 0px!important;
  }
  margin-top: -60px!important;
  z-index: -1;
  .contentIntro {
    background-image: url('../../../Pictures/backgrounds/coverMobile.jpg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 700px;
    .insideContent {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .textContainer {
        position: relative;
        top: 135px;
        padding: 32px;
        flex-grow: 1;
        max-width: 30em;
        text-align: center;
        a {
          display: block;
          text-align: center;
          display: flex;
          justify-content: center;
        }
        .helloTitle {
          color: white;
          text-align: left;
          font-size: 37px;
          font-weight: bold;
          margin-bottom: -5px;
        }
        .text {
          text-align: center;
          color: white;
          font-size: 30px;
          max-width: 360px;
          margin: auto;
          font-family: 'Muli', sans-serif;
          .important {
            font-family: 'Muli', sans-serif;
            font-weight: 700;
          }
        }
        .baseButton {
          margin: auto;
          height: 65px;
          visibility: visible!important;
          margin-top: 62px!important;
          width: 246px;
          border-radius: 34px;
          background-color: $blue;
          color: white;
          font-size: 16px;
          font-weight: 600;
          &:focus {
            outline: none;
          }
          .arrow {
            width: 9px;
            position: relative;
            top: 1px;
          }
        }
      }
      .exampleWebSite {
        padding-left: 32px;
        padding-right: 32px;
        .mixlabExample {
          position: relative;
          top: 12px;
          margin: auto;
          display: block;
          max-width: 450px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.IntroductionMobile__content__insideContent__textContainer__subText {
  color: white;
  font-size: 14px!important;
  line-height: 20px!important;
  max-width: 345px;
  .important {
    font-weight: 700;
  }
}

.IntroductionMobile__content__insideContent__textContainer__button {
  display: flex;
  align-items: center;
}

.IntroductionMobile__content__insideContent__textContainer__button__emoji {
  width: 20px;
}

