@import '../../../../../Color';
@import '../../../../../GlobalVariables';
.InvoicesListElement {
  display: flex;
  padding-bottom: 25px;
  border-bottom: 1px solid $grey-light;
}

.InvoicesListElement__textWrapper {
  flex-grow: 1;
}

.InvoicesListElement__textWrapper__date {
  color: $grey;
}

.InvoicesListElement__textWrapper__title {
  a {
    color: $font-color;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.InvoicesListElement__priceWrapper {
  width:141px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.InvoicesListElement__priceWrapper__price {
  font-size: 18px;
  font-weight: 600;
}

.InvoicesListElement__priceWrapper__refund {
  color: $grey;
  font-size: 12px;
}

.InvoicesListElement__priceWrapper__pdfWrapper__pdfError {
  font-size: 12px;
  color: $pink;
  text-align: right;
}

.InvoicesListElement__priceWrapper__pdfWrapper__pdf {
  color: $blue;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

.InvoicesListElement__priceWrapper__pdfWrapper__unavailable {
  color: $grey;
  font-size: 12px;
}

.InvoicesList__list__hasMore {
  text-align: center;
  color: $blue;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}

.InvoicesList__list__errorHasMore {
  text-align: center;
  color: $pink;
}

