
@import '../../../../../Color';
@import '../../../../../GlobalVariables';


.RegisterTopModalContent {
  position: relative;
}


.RegisterTopModalContent__logoContainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -105px;
}

.RegisterTopModalContent__logoContainer__communityLogo {
  $size: 80px;
  background-color: white;
  border: 5px solid white;
  width: $size;
  height: $size;
  border-radius: 50%;
}

.RegisterTopModalContent__logoContainer__communityLogo__backgroundValid {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(180deg, #2CE352 0%, #00D183 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegisterTopModalContent__communityLogo__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.RegisterTopModalContent__logoContainer__communityLogo__backgroundValid__image {
  width: 51%;
  height: 51%;
}

.RegisterTopModalContent__startDate {
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 600;
}