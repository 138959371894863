
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.LostPasswordModalContent {
}

.LostPasswordModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.LostPasswordModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.LostPasswordModalContent__content__sentence {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.LostPasswordModalContent__content__actions {
  text-align: center;
  font-size: 14px;
  color: $grey;
}

.LostPasswordModalContent__content__actions__signIn {
  &:hover {
    text-decoration: underline;
  }
}