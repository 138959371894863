
@import '../../../Color';
@import '../../../GlobalVariables';

#CGV {
  .content {
    margin: auto;
    margin-top: 4em;
    max-width: 700px;
    padding: 32px;
    white-space: pre-wrap;
    text-align: justify;
  }
}


