
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.PasswordUpdateModalContent {
}

.PasswordUpdateModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.PasswordUpdateModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.PasswordUpdateModalContent__content__sentence {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.PasswordUpdateModalContent__content__subSentence {
  text-align: center;
  font-size: 12px;
  color: $grey;
}

.PasswordUpdateModalContent__content__actions {
  text-align: center;
  font-size: 14px;
  color: $grey;
}