
@import '../../../Color';
@import '../../../GlobalVariables';

#SideText {
    &.background {
        background-color: #FBFCFE;
    }
    .circle {
        width: 110%;
        position: relative;
        left: -5%;
        height: 100px;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        &.top {
            background: white;
        }
        &.bottom {
            background: #FBFCFE;
            position: relative;
            top: 25px;
        }
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 65em;
        margin: auto;
        padding: 71px 32px 0px;
        overflow: hidden;
        width: calc(100% - 64px);
        .right {
            .illustration {
                margin-right: 5em;
                @media only screen and (max-width: 900px) {
                    margin-right: 0;
                }
            }
        }
        @media only screen and (max-width: 900px) {
            flex-wrap: wrap;
        }
        &.reversed {
            flex-flow: row-reverse;
            @media only screen and (max-width: 900px) {
                flex-wrap: wrap;
            }
            .right {
                .illustration {
                    margin-left: 5em;
                    margin-right: 0;
                    @media only screen and (max-width: 900px) {
                        margin-left: 0;
                    }
                }
            }
        }
        .left {
            max-width: 490px;
            @media only screen and (max-width: 900px) {
                max-width:100%;
                margin-top: 32px;
            }
            h2 {
                text-align: left;
                font-weight: 700;
                margin-top: 0;
            }
            text-align: justify;
            .emoji {
                vertical-align: middle;
                height: auto;
                width: 20px;
            }
        }
        .right {
            max-width: 34em;
            .illustration {
                height: 100%;
                max-width: 500px;
                flex-shrink: 0;
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}


