
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.ChallengeUnregisterModalContent {
}

.ChallengeUnregisterModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.ChallengeUnregisterModalContent__content {
  text-align: center;
  padding: 20px;
}

.ChallengeUnregisterModalContent__content__title {
  font-weight: 600;
}

.ChallengeUnregisterModalContent__content__subTitle {
  font-size: 12px;
  color: $grey;
}

.ChallengeUnregisterModalContent__content__buttonWrapper {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin: auto;
  margin-top: 30px;
  button {
    display: block;
  }
}
