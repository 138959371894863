
@import '../../../Color';
@import '../../../GlobalVariables';

#Introduction {
  @media only screen and (max-width: 900px) {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin-top: 0px!important;
  }
  max-width: 1400px;
  position: relative;
  margin:auto;
  padding: 0px 16px;
  margin-top: -60px!important;
  z-index: 0;
  .content {
    background-image: url('../../../Pictures/backgrounds/coverWeb.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    width: 136%;
    left: -18%;
    height: 650px;
    padding: 0px 55px;
    display: flex;
    align-items: center;
    .insideContent {
      width: 70%;
      top: 60px;
      position: relative;
      left: -2%;
      margin: auto;
      display: flex;
      align-items: center;
    }
    .textContainer {
      min-width: 30%;
      // max-width: 29%;
      .helloTitle {
        text-align: left;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 16px;
      }
      // position: absolute;
      // top: 170px;
      // left: 17%;
      .text {
        margin-top: 37px;
        max-width: 580px;
        text-align: left;
        line-height: 55px;
        font-size: 40px;
        font-weight: 800;
        line-height: 50px;
        font-family: 'Muli', sans-serif;
        margin-bottom: 0;
        .important {
          font-family: 'Muli', sans-serif;
          font-weight: 700;
        }
      }
      .baseButton {
        height: 65px;
        visibility: visible!important;
        margin-top: 62px!important;
        width: 246px;
        border-radius: 34px;
        background-color: $blue;
        color: white;
        font-size: 16px;
        font-weight: 600;
        &:focus {
          outline: none;
        }
        .arrow {
          width: 9px;
          position: relative;
          top: 1px;
        }
      }
    }
    .exampleWebSite {
      flex-grow: 1;
      max-width: 50%;
      bottom: 0px;
      margin-left: 32px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    .mixlabExample {
      height: 349px;
      width: 429px;
      object-fit: cover;
    }
  }
}

.Introduction__content__insideContent__textContainer__subText {
  font-size: 16px!important;
  line-height: 30px!important;
  max-width: 90%;
  .important {
    font-weight: 800;
  }
}

.Introduction__content__insideContent__textContainer__button__link {
  display: block;
}

.Introduction__content__insideContent__textContainer__button {
  display: flex;
  align-items: center;
}

.Introduction__content__insideContent__textContainer__button__emoji {
  width: 20px;
  position: relative;
  top: 4px;
}


