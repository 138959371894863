@import "../../../Color";
@import "../../../GlobalVariables";

.ChallengePageContent {
  max-width: 800px;
  margin: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 64px;
}

.ChallengePageContent__dateAndCommunity {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: auto;
  margin-bottom: 16px;
  max-width: 480px;
  width: auto;
}

.ChallengePageContent__dateAndCommunity__communityCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChallengePageContent__dateAndCommunity__communityCircle__container {
  position: relative;
}

.ChallengePageContent__dateAndCommunity__communityCircle__container__image {
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.ChallengePageContent__dateAndCommunity__fullDateStringRow {
  margin-left: 12px;
}

.ChallengePageContent__dateAndCommunity__fullDateStringRow__dateStringRow {
  text-transform: capitalize;
  font-size: 25px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 4px;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
}
.ChallengePageContent__dateAndCommunity__fullDateStringRow__dateStringRow__hourStringRow {
  font-size: 14px;
}

.ChallengePageContent__title {
  font-weight: 600;
  font-size: 35px;
  text-align: center;
}

.ChallengePageContent__coverRow {
  position: relative;
}

.ChallengePageContent__coverRow__image {
  display: block;
  margin: auto;
  border-radius: 20px;
  max-width: 100%;
  max-height: 25em;
}

.ChallengePageContent__description {
  position: relative;
  overflow-wrap: break-word;
  text-align: left;
  padding: 45px;
  background-color: #fafbfc;
  border-radius: 20px;
  font-size: 18px;
  hyphens: auto;
  white-space: pre-line;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
  .blue {
    color: $blue;
  }
}

.ChallengePageContent__secretInfos {
  position: relative;
  overflow-wrap: break-word;
  text-align: left;
  padding: 35px 45px;
  background-color: #fafbfc;
  border-radius: 20px;
  font-size: 20px;
  hyphens: auto;
  white-space: pre-line;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
  .blue {
    color: $blue;
  }
}

.ChallengePageContent__secretInfos__element__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
}

.ChallengePageContent__secretInfos__element__text {
  font-size: 16px;
  font-weight: 300;
  color: $grey;
}

.ChallengePageContent__attendeesTitle {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.ChallengePageContent__attendeesTitle__dot {
  font-size: 17px;
  top: -3px;
  padding: 0px 12px;
  background-color: #357cfb;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 12px;
  line-height: 19px;
  color: white;
  position: relative;
  top: -3px;
  margin-left: 5px;
}

.ChallengePageContent__publicLinkContent {
  position: relative;
  overflow-wrap: break-word;
  text-align: left;
  padding: 35px 45px;
  background-color: $green-light;
  color: $green;
  display: flex;
  border-radius: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.ChallengePageContent__publicLinkContent__imageRow {
  display: flex;
  align-items: center;
  width: 100px;
  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
    img {
      margin: auto;
    }
  }
}

.ChallengePageContent__publicLinkContent__imageRow__image {
  width: 80%;
}

.ChallengePageContent__publicLinkContent__text {
  font-size: 19px;
  line-height: 28px;
}

.ChallengePageContent__referrer {
  display: flex;
  align-items: center;
  padding: 35px 45px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.ChallengePageContent__referrer__logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  flex-shrink: 0;
}

.ChallengePageContent__referrer__logoWrapper__logoContainer {
  $size: 60px;
  width: $size;
  height: $size;
  background-image: url("../../../Pictures/Logos/logo.png");
  background-position: 18px 16px;
  background-repeat: no-repeat;
  background-size: 23px;
  border-radius: 50%;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.ChallengePageContent__referrer__text {
  margin-left: 22px;
  @media screen and (max-width: 600px) {
    margin-left: 0;
    margin-top: 21px;
    text-align: center;
  }
}

.ChallengePageContent__referrer__link {
  flex-grow: 1;
}

.ChallengePageContent__referrer__button {
  width: 160px;
  flex-shrink: 0;
  margin-left: 27px;
  @media screen and (max-width: 600px) {
    margin-left: 0;
    margin-top: 21px;
  }
  .blue {
    padding: 10px 30px 12px 30px;
  }
}
