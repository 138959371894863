@import '../../../../Color';
@import '../../../../GlobalVariables';

.AgendaFiles {
  margin: auto;
}

.AgendaFiles__row {
  display: flex;
  max-width: 18em;
  margin: auto;
  margin-top: 14px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.AgendaFiles__row__type {
  flex-shrink: 0;
  cursor: pointer;
  padding: 0 12px;
  text-align: center;
}

.AgendaFiles__row__type__content {
  img {
    width: 50px;
    height: auto;
    object-fit: contain;
  }
  p {
    font-size: 12px;
    margin-top: -4px;
  }
} 

