@import "../../../GlobalVariables";
@import "../../../Color";

.ChallengeInformations {
  position: relative;
  overflow-wrap: break-word;
  text-align: left;
  padding: 35px 45px;
  background-color: #FAFBFC;
  border-radius: 20px;
  font-size: 20px;
  hyphens: auto;
  white-space: pre-line;
  @media screen and (max-width: 500px){
    font-size: 16px;
  }
  .blue {
    color:$blue;
  }
  a {
    color: $blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    } 
  }
}

.ChallengeInformations__element__title  {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.ChallengeInformations__element__text {
  font-size: 16px;
  font-weight: 400;
  color: $grey;
  &.visible {
    color: $font-color; 
  }
}

