
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.CancelModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.CancelModalContent__content__title {
  font-weight: 400;
  font-size: 12px;
  color: $grey;
  text-align: center;
}

.CancelModalContent__content__sentence {
  font-weight: 600;
  text-align: center;
}

.CancelModalContent__content__button {
  width: 100%;
}