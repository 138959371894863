@import "../../../Color.scss";

.PlaceholderImage {
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  &.empty {
    color: $blue;
  }
}