
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.DeleteConfirmModalContent {
}

.DeleteConfirmModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.DeleteConfirmModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.DeleteConfirmModalContent__content__sentence {
  text-align: center;
  font-size: 16px;
}

.DeleteConfirmModalContent__content__subSentence {
  text-align: center;
  font-size: 12px;
  color: $grey;
}

.PasswordModalContent__content__errors {
  color: $pink;
  text-align: center;
  font-size: 12px;
}

.DeleteConfirmModalContent__content__actions {
  text-align: center;
  font-size: 14px;
  color: $grey;
}