
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.MagicLinkEmailSentModalContent {
}

.MagicLinkEmailSentModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.MagicLinkEmailSentModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.MagicLinkEmailSentModalContent__content__imageRow {
  $size: 100px;
  width: $size;
  height: $size;
  border-radius: 50%;
  margin: auto;
  background: linear-gradient(180deg, #2CE352 0%, #00D183 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MagicLinkEmailSentModalContent__content__imageRow__image {
  display: block;
  width: 50%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.MagicLinkEmailSentModalContent__content__sentence {
  text-align: center;
  color: $green;
}

.MagicLinkEmailSentModalContent__content__bottomSentence {
  color: $grey;
  font-size: 10px;
  text-align: center;
}