
@import '../../../../../Color';
@import '../../../../../GlobalVariables';

.SignUpRegisterModalContent {
}

.SignUpRegisterModalContent__TopModalSquare__title {
  font-size: 25px;
  font-weight: 600;
}

.SignUpRegisterModalContent__content {
  max-width: 16em;
  padding: 32px;
  margin: auto;
}

.SignUpRegisterModalContent__content__sentence {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.SignUpRegisterModalContent__content__actions {
  text-align: center;
  font-size: 14px;
  color: $grey;
}

.SignUpRegisterModalContent__content__actions__signUp {
  &:hover {
    text-decoration: underline;
  }
}