
@import '../../../Color';
@import '../../../GlobalVariables';

.ChallengePageBottomBar {
  background-color: white;
  border-top: 1px solid $grey-xtra-light;
  height: 70px;
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChallengePageBottomBar__button {
  height: 80%;
  min-width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.ChallengePageBottomBar__button__logo {
  width: 15px;
  position: relative;
  top: 1px;
}

.ChallengePageBottomBar__error {
  color: $pink;
  font-size: 12px;
  max-width: 20em;
  text-align: center;
}